import {PageProps} from "gatsby";
import * as React from "react";
import ContractsPartSpecial from "../misc/contracts_part_special.mdx";
import ContractsPartNormal from "../misc/contracts_part_normal.mdx";
import ContractsPartPrivacy from "../misc/contracts_part_privacy.mdx";
import {Layout} from "../components/layout";

export default (props: PageProps) => {
    const params = Object.fromEntries(new URLSearchParams(props.location.search))

    return <Layout>
        <div className="break-all px-4 py-4 leading-8">
            {parseParam(params.showSpecial) && <ContractsPartSpecial/>}
            {parseParam(params.showNormal) && <ContractsPartNormal/>}
            {parseParam(params.showPrivacy) && <ContractsPartPrivacy/>}
        </div>
    </Layout>
}

function parseParam(param?: string): boolean {
    if (param == null) return true
    if (param.toLowerCase() == "false") return false
    if (param.toLowerCase() == "true") return true
    return true // fallback
}